import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormHelperText, Grid, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import PeopleIcon from "@mui/icons-material/People";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CompanySubscriptionService } from "../../api/customer/CompanySubscriptionService";
import { ResponseCode } from "../../constant/ResponseCode";
import { CompanySubscriptionRequest, CompanySubscriptionRequestSchema } from "../../type/CompanySubscriptionRequest";
import cities from "../Data/cities";
import PageHeader from "../Layout/PageHeader";


const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const CompanySubscription = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, formState: { errors }, setValue, control, watch } = useForm<CompanySubscriptionRequest>({
    defaultValues: (() => {
      const storedData = sessionStorage.getItem('subscriptionFormData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        sessionStorage.removeItem('subscriptionFormData');
        
        return parsedData;
        
      }
      
      return CompanySubscriptionRequest.create();
    })(),
    resolver: yupResolver(CompanySubscriptionRequestSchema(t)),
  });

  const [districts, setDistricts] = useState<string[]>([]);
  const [city, setCity] = useState<string>("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 5;
  const history = useHistory();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  const handleCaptchaChange = (token: string | null) => {
    if (token) {
      setIsCaptchaVerified(true);
      setValue('captcha', token); // react-hook-form setValue kullanarak captcha'yı form datasına set et
    } else {
      setIsCaptchaVerified(false);
      setValue('captcha', '');
    }
  };

  const fillDistricts = (selectedCity: string) => {
    const counties = cities.filter((obj) => obj.il === selectedCity);
    if (counties.length > 0) setDistricts(counties[0]["ilceleri"]);
  };

  useEffect(() => {
    // Eğer default city set edilmişse, districts de doldurulmalı
    if (watch('company.city')) {
      fillDistricts(watch('company.city'));
    }
  }, [watch('company.city')]);

  const onSubmit: SubmitHandler<CompanySubscriptionRequest> = async (data) => {
    try {
      if (!isCaptchaVerified) {
        toast.error("Lütfen captcha doğrulamasını tamamlayın");
        return;
      }

      setIsSubmitting(true);
      const response = await CompanySubscriptionService(data, setError);
      if (response.code === ResponseCode.SUCCESS) {
        toast.success(t('companySubscription:ApplicationSent'));
        history.push('/login');
      }

      recaptchaRef.current?.reset();
      setIsCaptchaVerified(false);
      return;
    }
    catch (error) {
      toast.error(t("common.error"));
    } finally {
      setIsSubmitting(false);
    }
  };

  const companyType = watch("company.type");

  useEffect(() => {
    console.log("useeffecte girdi.")
    if (companyType === "Gerçek") {
      setValue('company.publicOrPrivate', '');  // Reset value when companyType is "Gerçek"
    }
  }, [companyType, setValue]);

  return (
    <>
      <PageHeader
        title={t('common:CompanyInfo')}
        subTitle={t('companySubscription:CanBeCustomer')}
        icon={<PeopleIcon fontSize="large" color="action" />}
      />
      <Paper className={classes.pageContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <>
              <Typography>
                <Box sx={{ fontWeight: "bold" }}>{t('common:CompanyInfo')}</Box>
              </Typography>
              <div style={{ width: "100%" }}>
                <Box
                  //align="center"
                  sx={{
                    display: "grid",
                    rowGap: 3,
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:CompanyName')}
                    placeholder={t('common:CompanyName')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    {...register("company.name")}
                    error={!!errors.company?.name}  // Eğer hata varsa, `error`'ı true yap
                    helperText={errors.company?.name ? errors.company.name.message : ''}  // Hata mesajını göster
                    inputProps={{
                      maxLength: 255,  // Maksimum uzunluk
                    }}
                  />
                  <FormControl fullWidth variant="outlined"
                    sx={{ marginTop: "15px" }}
                    error={!!errors.company?.type}>
                    <Controller
                      name="company.type"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <InputLabel id="firmTypeLabel">{t('common:CompanyType')}</InputLabel>
                          <Select
                            labelId="firmTypeLabel"
                            fullWidth
                            variant="outlined"
                            id="firmTypeSelect"
                            label={t('common:CompanyType')}
                            {...register("company.type")}
                            MenuProps={MenuProps}
                            {...field}
                          >
                            <MenuItem sx={{ minWidth: 200 }} value={"Tüzel"}>
                              {t('common:CompanyTypes:Corporate')}
                            </MenuItem>
                            <MenuItem sx={{ minWidth: 200 }} value={"Gerçek"}>
                              {t('common:CompanyTypes:SoleProprietorship')}
                            </MenuItem>
                          </Select>
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </div>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                  }}
                >
                  <FormControl fullWidth variant="outlined"
                    sx={{ marginTop: "15px" }}
                    error={!!errors.company?.type}>
                    <Controller
                      name="company.publicOrPrivate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <InputLabel id="publicOrPrivateLabel">
                            {t('common:PublicOrPrivate')}
                          </InputLabel>
                          <Select
                            labelId="publicOrPrivateLabel"
                            //margin="normal"
                            fullWidth
                            variant="outlined"
                            id="publicOrPrivateSelect"
                            label={t('common:PublicOrPrivate')}
                            disabled={companyType !== "Tüzel"}
                            {...register("company.publicOrPrivate")}
                            MenuProps={MenuProps}
                            {...field}
                          >
                            <MenuItem sx={{ minWidth: 200 }} value={"Kamu"}>
                              {t('common:PublicOrPrivates:Public')}
                            </MenuItem>
                            <MenuItem sx={{ minWidth: 200 }} value={"Özel"}>
                              {t('common:PublicOrPrivates:Private')}
                            </MenuItem>
                          </Select>
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  <TextField
                    label={t('common:Title')}
                    placeholder={t('common:Title')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.title")}
                    error={!!errors.company?.title}
                    helperText={errors.company?.title ? errors.company.title.message : ''}
                    inputProps={{
                      maxLength: 255,  // Maksimum uzunluk
                    }}
                  />
                </Box>
              </div>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:TaxOffice')}
                    placeholder={t('common:TaxOffice')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.taxOffice", { required: t('common:NotBlank') })}
                    error={!!errors.company?.taxOffice}
                    helperText={errors.company?.taxOffice ? errors.company.taxOffice.message : ''}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                  <FormControl>
                    <TextField
                      label={t('common:TaxIdentityNumber')}
                      placeholder={t('common:TaxIdentityNumber')}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      {...register("company.identityNumber", { required: t('common:NotBlank') })}
                      error={!!errors.company?.identityNumber}
                      helperText={errors.company?.identityNumber ? errors.company.identityNumber.message : ''}
                      onInput={(e) => {
                        // Tür dönüşümünü yapıyoruz: e.target'ı HTMLInputElement olarak belirtiyoruz
                        const input = e.target as HTMLInputElement;
                        // Sadece sayıları kabul et
                        input.value = input.value.replace(/[^0-9]/g, '');
                      }}
                      inputProps={{
                        inputMode: "numeric",  // Sayısal klavye açılması için
                        maxLength: 11,         // Maksimum uzunluğu 10 yapalım
                      }}
                    />
                  </FormControl>
                </Box>
              </div>
              <Typography>
                <Box sx={{ fontWeight: "bold" }}>{t('common:BankInfo')}</Box>
              </Typography>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:BankName')}
                    placeholder={t('common:BankName')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.bankName")}
                    error={!!errors.company?.bankName}
                    helperText={errors.company?.bankName ? errors.company.bankName.message : ''}
                    inputProps={{
                      maxLength: 100,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                  <TextField
                    label={t('common:BranchName')}
                    placeholder={t('common:BranchName')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.bankBranchName")}
                    error={!!errors.company?.bankBranchName}
                    helperText={errors.company?.bankBranchName ? errors.company.bankBranchName.message : ''}
                    inputProps={{
                      maxLength: 100,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Box>
              </div>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:Iban')}
                    placeholder={t('common:Iban')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.iban")}
                    error={!!errors.company?.iban}
                    helperText={errors.company?.iban ? errors.company.iban.message : ''}
                    inputProps={{
                      maxLength: 34,
                    }}
                  />
                </Box>
              </div>
              <Typography>
                <Box sx={{ fontWeight: "bold" }}>{t('common:CommunicationInfo')}</Box>
              </Typography>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:EmailAddress')}
                    placeholder={t('common:EmailAddress')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.email")}
                    error={!!errors.company?.email}
                    helperText={errors.company?.email ? errors.company.email.message : ''}
                    inputProps={{
                      maxLength: 150,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                  <TextField
                    label={t('common:PhoneNumber')}
                    placeholder={t('common:PhoneNumber')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    //name="firmPhoneNumber"
                    {...register("company.phoneNumber")}
                    error={!!errors.company?.phoneNumber}
                    helperText={errors.company?.phoneNumber ? errors.company.phoneNumber.message : ''}
                    onInput={(e) => {
                      // Tür dönüşümünü yapıyoruz: e.target'ı HTMLInputElement olarak belirtiyoruz
                      const input = e.target as HTMLInputElement;
                      // Sadece sayıları kabul et
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{
                      inputMode: "numeric",  // Sayısal klavye açılması için
                      maxLength: 10,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Box>
              </div>
              <Typography>
                <Box sx={{ fontWeight: "bold" }}>{t('common:AddressInfo')}</Box>
              </Typography>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                    marginTop: "15px"
                  }}
                >
                  <FormControl fullWidth variant="outlined">
        <Controller
          name="company.city"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <InputLabel id="demo-simple-select-label">{t('common:City')}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                label={t('common:City')}
                value={city || watch('company.city') || ''}
                fullWidth
                MenuProps={MenuProps}
                {...register("company.city")}
                onChange={(e) => {
                  const selectedCity = e.target.value;
                  setCity(selectedCity);
                  fillDistricts(selectedCity);
                  setValue("company.city", selectedCity); // Şehir değişince form değerini güncelle
                }}
              >
                {cities.map((c, index) => (
                  <MenuItem sx={{ minWidth: 180 }} value={c.il} key={index}>
                    {c.il}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </>
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <Controller
          name="company.district"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <InputLabel id="demo-simple-select-label">{t('common:District')}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                label={t('common:District')}
                fullWidth
                MenuProps={MenuProps}
                {...register("company.district")}
                value={watch('company.district') || ''}
              >
                {districts.map((district, index) => (
                  <MenuItem sx={{ minWidth: 280 }} value={district} key={index}>
                    {district}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </>
          )}
        />
      </FormControl>
                </Box>
              </div>
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 3,
                  }}
                >
                  <TextField
                    label={t('common:Address')}
                    placeholder={t('common:Address')}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    {...register("company.address")}
                    error={!!errors.company?.address}
                    helperText={errors.company?.address ? errors.company.address.message : ''}
                    inputProps={{
                      maxLength: 400,
                    }}
                  />
                </Box>
              </div>
            </>

            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t('common:CompanyRepresentativeInfo')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('common:Name')}
                    {...register("representative.firstName")}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.representative?.firstName}
                    helperText={errors.representative?.firstName ? errors.representative.firstName.message : ''}
                    inputProps={{
                      maxLength: 30,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('common:LastName')}
                    //name="lastName"
                    {...register("representative.lastName")}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.representative?.lastName}
                    helperText={errors.representative?.lastName ? errors.representative.lastName.message : ''}
                    inputProps={{
                      maxLength: 30,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('common:EmailAddress')}
                    {...register("representative.username")}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.representative?.username}
                    helperText={errors.representative?.username ? errors.representative.username.message : ''}
                    inputProps={{
                      maxLength: 150,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="5XX XXX XX XX"
                    label={t('common:PhoneNumber')}
                    {...register("representative.phoneNumber")}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.representative?.phoneNumber}
                    helperText={errors.representative?.phoneNumber ? errors.representative.phoneNumber.message : ''}
                    onInput={(e) => {
                      // Tür dönüşümünü yapıyoruz: e.target'ı HTMLInputElement olarak belirtiyoruz
                      const input = e.target as HTMLInputElement;
                      // Sadece sayıları kabul et
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{
                      inputMode: "numeric",  // Sayısal klavye açılması için
                      maxLength: 10,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('common:TurkishRepublicIdentityNumber')}

                    {...register("representative.identityNumber")}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={!!errors.representative?.identityNumber}
                    helperText={errors.representative?.identityNumber ? errors.representative.identityNumber.message : ''}
                    onInput={(e) => {
                      // Tür dönüşümünü yapıyoruz: e.target'ı HTMLInputElement olarak belirtiyoruz
                      const input = e.target as HTMLInputElement;
                      // Sadece sayıları kabul et
                      input.value = input.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{
                      inputMode: "numeric",  // Sayısal klavye açılması için
                      maxLength: 11,         // Maksimum uzunluğu 10 yapalım
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                label={t('common:Password')}
                {...register("representative.password")}
                fullWidth
                variant="outlined"
                margin="normal"
                error={!!errors.representative?.password}
                helperText={errors.representative?.password ? errors.representative.password.message : ''}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                label={t('common:ConfirmPassword')}
                {...register("representative.confirmPassword")}
                fullWidth
                variant="outlined"
                margin="normal"
                error={!!errors.representative?.confirmPassword}
                helperText={errors.representative?.confirmPassword ? errors.representative.confirmPassword.message : ''}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </Grid>
            <Grid item xs={12}>
  <Box 
    textAlign="center" 
    display="flex" 
    flexDirection="column" 
    alignItems="center"
  >
    {/* ReCAPTCHA container */}
    <Box
      width="302px" // ReCAPTCHA'nın genişliğine uyumlu
      height="78px" // ReCAPTCHA'nın yüksekliğine uyumlu
      display="flex"
      justifyContent="center" 
      alignItems="center"
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET as string}
        size="normal" // normal size for ReCAPTCHA
        onChange={handleCaptchaChange}
      />
    </Box>

    {/* Error message alert */}
    {errors.captcha && (
      <Alert severity="error" sx={{ width: '302px', marginTop: 2 }}>
        {errors.captcha?.message}
      </Alert>
    )}

    {/* Submit Button */}
    <Box width="302px" marginTop={2}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting || !isCaptchaVerified}
        fullWidth
        className={`${isSubmitting || !isCaptchaVerified ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {t('companySubscription:SendApplication')}
      </Button>
    </Box>
  </Box>
</Grid>



          </Grid>
        </form>
      </Paper>
      <ToastContainer />
    </>
  );
}

export default CompanySubscription;

