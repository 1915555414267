import { Clock, FileText, PlusCircle, Send, X } from 'lucide-react';
import { React, useState } from 'react';
import { Link } from 'react-router-dom'; // Link bileşenini import ediyoruz
import { DocumentTypes } from '../../actions/types';

const ActionButtons = ({ onSearch, counts = {
  incoming: 0,
  draft: 0,
  approve: 0
}, handleCreateDocument }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const buttons = [
    {
      icon: <PlusCircle size={20} />,
      label: 'Süreç Başlat',
      path: '/startflow'
    },
    {
      icon: <PlusCircle size={20} />,
      label: 'Doküman Oluştur',
      path: '/entryBasicContract',
      onClick: handleCreateDocument
    },
    {
      icon: <Clock size={20} />,
      label: 'Onayımda Bekleyenler',
      path: '/incomingDocuments',
      documentType: DocumentTypes.All,
      count: counts.incoming
    },
    {
      icon: <Send size={20} />,
      label: 'Onaya Gönderdiklerim',
      path: '/outgoingDocuments',
      documentType: DocumentTypes.All,
      count: counts.approve
    },
    {
      icon: <FileText size={20} />,
      label: 'Taslak Dokümanlar',
      path: '/draftDocuments',
      documentType: DocumentTypes.All,
      count: counts.draft
    }/* ,
    {
      icon: <Archive size={20} />,
      label: 'Arşivlenenler',
      path: '/archivedDocuments',
      documentType: DocumentTypes.All
    } */
  ];

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    if (!showSearch) {
      setSearchTerm('');
      onSearch(''); // Reset search when opening
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 3) {
      onSearch(value);
    } else if (value.length === 0) {
      onSearch(''); // Reset search when clearing
    }
  };

  return (
    <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
    padding: '1.5rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '1.5rem',
  }}
>
  {buttons.map((btn) => (
    <div
      key={btn.label}
      style={{
        position: 'relative',
        display: 'inline-block',
      }}
    >
      <Link
        to={{
          pathname: btn.path,
          state: { documentType: btn.documentType },
        }}
        onClick = {btn.onClick? btn.onClick : null}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          padding: '0.5rem 1rem',
          backgroundColor: '#2B4B80',
          color: 'white',
          borderRadius: '0.5rem',
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'none',
          transition: 'background-color 0.3s',
        }}
      >
        {btn.icon}
        <span style={{ fontSize: '0.875rem' }}>{btn.label}</span>
        {btn.count > 0 && (
              <span className="ml-2 px-2 py-1 text-xs bg-red-500 rounded-full">
                {btn.count}
              </span>
            )}
      </Link>
    </div>
  ))}

  {/* Müşteriye Göre Ara bölümü */}
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div className="relative">
      {/* Search input ve button */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Müşteri ara..."
          className="p-2 border rounded-md w-64"
          autoFocus
          style={{
            padding: '0.5rem 1rem',   // Butonlarla aynı padding
            fontSize: '0.875rem',      // Aynı font boyutu
            height: '2.5rem',          // Butonlarla aynı yükseklik
            lineHeight: '2.5rem',      // Yüksekliğe göre hizalama
            marginTop: '0px',          // Arama inputu yukarı kaydırılacak
          }}
        />
        <button
          onClick={() => {
            setSearchTerm('');
            onSearch('');
            setShowSearch(false);
          }}
          className="ml-2 p-2 rounded-md bg-gray-200 hover:bg-gray-300"
          style={{
            height: '2.5rem', // Butonla aynı yükseklik
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0, // Fazla padding yok
          }}
        >
          <X size={20} />
        </button>
      </div>
    </div>
  </div>
</div>



  );
};

export default ActionButtons;