import * as Validation from "yup";

export interface UploadedDocumentInfo {  
    id: number | null;
    name: string;
    path: string | null;
    status: number | null;
    content: string;
    documentTypeId: number | null;
    documentDate: string | null;
}

export class UploadedDocumentInfo implements UploadedDocumentInfo {
    id: number | null = null;
    name = "";
    path: string | null = null;
    status: number | null = null;
    content: string = "";
    documentTypeId: number | null = null;
    documentDate: string | null = null;

    static create(): UploadedDocumentInfo {
        return new UploadedDocumentInfo();
    }
}

export const UploadedDocumentInfoSchema = (t: Function) => 
  Validation.object().shape({
    name: Validation.string().required(t('validation:NotBlank')),
    id: Validation.number()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    status: Validation.number()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    content: Validation.string().required(t('validation:NotBlank')),
    path: Validation.string()
      .nullable()
      .default(null)
      .transform((value) => value === undefined ? null : value),
    documentTypeId: Validation.number()
    .nullable()
    .default(null)
    .transform((value) => value === undefined ? null : value), 
    documentDate: Validation.string()
    .nullable()
    .default(null)
    .transform((value) => value === undefined ? null : value),
  });