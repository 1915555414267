import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import EditNote from "@mui/icons-material/Api";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditNoteIcon from "@mui/icons-material/DesignServices";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import PeopleAlt from "@mui/icons-material/Group";
import OfferIcon from "@mui/icons-material/LocalOffer";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {
  Button
} from '@mui/material';
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Archive, PlusCircle } from 'lucide-react';
import { React, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/securityActions";
import { DocumentTypes } from '../../actions/types';
import "./Sidebar.scss";

const useStyles = makeStyles({
  list: {
    width: 250,
    display: 'block'
  },
});

export default function Sidebarr() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nestedUploadedDocument, setNestedUploadedDocument] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);
  const [nestedOffer, setNestedOffer] = useState(false);
  const [nestedInvoice, setNestedInvoice] = useState(false);
  const user = useSelector((state) => state.security.user);
  const [showIdentityMessage, setShowIdentityMessage] = useState(false);

  function Logout() {
    dispatch(logout());
    window.location.href = "/";
  }

  const handleClick = () => {
    setNestedOpen(!nestedOpen);
  };

  const offerClick = () => {
    setNestedOffer(!nestedOffer);
  };

  const invoiceClick = () => {
    setNestedInvoice(!nestedInvoice);
  };

  const handleCreateClick = (e) => {
    let isValid = (user.identityNumber != null && user.identityNumber != "");
    isValid = isValid || (user.company != null && user.company.identityNumber != null && user.company.identityNumber != "");  // Kontrol fonksiyonunuzu buraya yazın
    console.log("Valid Kontrol çalıştır:")
    console.log(isValid)
    if (!isValid) {

      e.preventDefault();
      setShowIdentityMessage(true)
    }
  };

  return (
    <>
      <div>
        <Modal show={showIdentityMessage} onHide={() => setShowIdentityMessage(false)}>
          <Modal.Body>Belge oluşturma ve diğer işlemler için TCKN bilginizi eğer bir şirket adına işlem yapacaksanız
            şirket bilgilerinizi Profil Menusunden girebilirsiniz.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowIdentityMessage(false)}
            >
              Tamam
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
      <div className={classes.list}>

        <List spacing={30}>
        <ListItemButton onClick={() => setNestedUploadedDocument(!nestedUploadedDocument)}>
            <ListItemIcon>
              <GradingOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Yüklenen Dokümanlar" />
            {nestedUploadedDocument ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={nestedUploadedDocument} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/draftUploadedDocuments" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <EditNote />
                    </ListItemIcon>
                    <ListItemText primary="Taslak" />
                  </ListItemButton>
                </List>
              </Link>
              <Link to="/outgoingUploadedDocuments" style={{ color: "black" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                  <ListItemText primary="Gönderilenler" />
                </ListItemButton>
              </Link>

              <Link to="/incomingUploadedDocuments" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gelenler" />
                  </ListItemButton>
                </List>
              </Link>
              {/* <Link to="/archiveContracts" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link> */}
            </List>
          </Collapse>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <GradingOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Sözleşmelerim" />
            {nestedOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/draftContracts" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <EditNote />
                    </ListItemIcon>
                    <ListItemText primary="Taslak" />
                  </ListItemButton>
                </List>
              </Link>
              <Link to="/outgoingContracts" style={{ color: "black" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                  <ListItemText primary="Gönderilenler" />
                </ListItemButton>
              </Link>

              <Link to="/incomingContracts" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gelenler" />
                  </ListItemButton>
                </List>
              </Link>
              {/* <Link to="/archiveContracts" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link> */}
            </List>
          </Collapse>
          {/* <ListItemButton>
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Taslak Oluştur" onClick={() => {
                                window.location.href = "/createContractDrafts";
                            }} />
                        </ListItemButton> */}
          <ListItemButton onClick={offerClick}>
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <ListItemText primary="Tekliflerim" />
            {nestedOffer ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={nestedOffer} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/draftOffers" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <EditNote />
                    </ListItemIcon>
                    <ListItemText primary="Taslak" />
                  </ListItemButton>
                </List>
              </Link>
              <Link to="/outgoingOffers" style={{ color: "black" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                  <ListItemText primary="Gönderilenler" />
                </ListItemButton>
              </Link>

              <Link to="/incomingOffers" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gelenler" />
                  </ListItemButton>
                </List>
              </Link>
              {/* <Link to="/archiveOffers" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link> */}
            </List>
          </Collapse>

          <ListItemButton onClick={invoiceClick}>
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Faturalarım" />
            {nestedOffer ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={nestedInvoice} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/draftInvoices" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <EditNote />
                    </ListItemIcon>
                    <ListItemText primary="Taslak" />
                  </ListItemButton>
                </List>
              </Link>
              <Link to="/outgoingInvoices" style={{ color: "black" }}>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary="Gönderilenler" onClick={() => {
                                        window.location.href = "/dashboard";
                                    }} /> */}
                  <ListItemText primary="Gönderilenler" />
                </ListItemButton>
              </Link>

              <Link to="/incomingInvoices" style={{ color: "black" }}>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gelenler" />
                  </ListItemButton>
                </List>
              </Link>
              {/* <Link to="/archiveInvoices" style={{ color: "black" }}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arşiv" />
                    </ListItemButton>
                  </List>
                </Link> */}
            </List>
          </Collapse>
          <Link to={{
            pathname: "/entryBasicContract",
          }} style={{ color: "black" }} onClick={handleCreateClick}>
            <ListItemButton>
              <ListItemIcon>
                <PlusCircle size={20} />
              </ListItemIcon>
              <ListItemText primary="Doküman Oluştur" />
            </ListItemButton>
          </Link>
          <Link to={{
            pathname: "/archivedDocuments",
            state: { documentType: DocumentTypes.All },
          }} style={{ color: "black" }}>
            <ListItemButton>
              <ListItemIcon>
                <Archive size={20} />
              </ListItemIcon>
              <ListItemText primary="Arşiv" />
            </ListItemButton>
          </Link>
          <Link to="/profile" style={{ color: "black", display: "block" }}>
            <ListItemButton>
              <ListItemIcon>
                <PersonOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Profil" />
            </ListItemButton>
          </Link>
          <Link to="/customers" style={{ color: "black", display: "block" }}>
            <ListItemButton>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Müşteriler" />
            </ListItemButton>
          </Link>
          <Link to="/templates" style={{ color: "black", display: "block" }}>
            <ListItemButton>
              <ListItemIcon>
                <EditNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Şablonlar" />
            </ListItemButton>
          </Link>
          <Link onClick={() => Logout()} style={{ color: "black", display: "block" }}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Çıkış" />
            </ListItemButton>
          </Link>
        </List>
      </div>
    </>
  );
}
