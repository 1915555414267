import ArchiveIcon from "@mui/icons-material/Archive";
import BrushIcon from "@mui/icons-material/Brush";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, trTR } from "@mui/x-data-grid";
import axios from "axios";
//import htmlToPdfmake from "html-to-pdfmake";
//import pdfMake from "pdfmake/build/pdfmake";
//import pdfFonts from "pdfmake/build/vfs_fonts";
import { FileSignature } from "lucide-react";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { DocumentStatus, DocumentTypes } from "../../actions/types";
import { blobToBase64, convertHtmlToPdf } from "../../util/pdf/PdfConverter";
import "../Layout/datatable.scss";
import { userColumns } from "../Layout/datatablesource";
import PageHeader from "../Layout/PageHeader";

//pdfMake.vfs = pdfFonts.pdfMake.vfs;

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Teklif Kaydı Bulunmamaktadır
    </Stack>
  );
}

const UploadedDocumentDraft = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.security.user);
  const userId = user.id;
  const [url, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [archiveShow, setArchiveShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [signedRow, setSignedRow] = useState(null);
  const identityNumber = user.company && user.company !== null ? user.company.identityNumber : user.identityNumber;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function sign() {
    setAnchorEl(null);
    const signedDocument = {
      id: signedRow.contractId,
      name: signedRow.generatedId + ".pdf",
      content: "",
      directory: identityNumber,
      type: 0
    };

    await axios.get(`api/contract/get-contract/${signedRow.contractId}`).then(async (res) => {
      let htmlToPdf = res.data["contractHTMLContent"];
      console.log(htmlToPdf);
      const pdfBlob = await convertHtmlToPdf(htmlToPdf);
      console.log("BLOB : ")
      console.log(pdfBlob);
      const base = await blobToBase64(pdfBlob);
      console.log("BASE : ")
      console.log(base);

      signedDocument.content = base;
      console.log(signedDocument);
      axios
        .post("api/sign/sign-digital", signedDocument)
        .then((response) => {
          if (response.data.isSigned)
            toast.success("Doküman imzalandı")
          else {
            toast.error(response.data.userMessage);
            console.log(response.data.error);
          }
        })
        .catch((error) => {
          toast.error("Doküman imzalanırken hata oluştu.");
          console.log(error);
        });

    }).catch((error) => {
      console.log(error);
    });
  };

  async function contractData(userId) {
    let request = {
      userId: userId,
      documentType: DocumentTypes.Offer,
      status: DocumentStatus.Draft
    }
    //console.log(request);
    await axios
      .post(`api/contract/get-outgoing-contracts`, request)
      .then((res) => {
        setData(res.data);
        //console.log(res.data);
      }).catch((error) => {
        console.log(error);
      });;
  }

  useEffect(() => {
    contractData(userId);
  }, []);

  async function handleDelete() {
    await axios
      .post(`api/contract/delete-contract/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setDeleteShow(false);
      }).catch((error) => {
        console.log(error);
      });

    //contractData(userId);
  }

  async function handleArchive() {
    await axios
      .post(`api/contract/move-contract-archive/${selectedRow.contractId}`)
      .then((res) => {
        contractData(userId);
        setArchiveShow(false);
      }).catch((error) => {
        console.log(error);
      });;

    //contractData(userId);
  }

  async function handleSendContract() {
    let contractStatus = {
      id: selectedRow.contractId,
      status: DocumentStatus.SentApprove
    }
    await axios
      .post(`api/contract/update-contract-status`, contractStatus)
      .then((res) => {
        contractData(userId);
        setApproveShow(false);
      }).catch((error) => {
        console.log(error);
      });;

    //contractData(userId);
  }

  /*   const create = (docDefinition) => {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        const url = URL.createObjectURL(blob);
        setUrl(url);
      });
    };
  
    async function showModal(contractId, show) {
      let docDefinition;
  
      if (show) {
        setShow(false);
      } else {
        await axios.get(`api/contract/get-document-file/${contractId}`).then((res) => {
          if(res.data.isSigned) {
            setUrl(res.data.preSignedUrl);
            setShow(true);
            return;
          }
  
          let content = res.data.htmlContent;
        
  
          
          let htmlToPdf = htmlToPdfmake(content, {
            tableAutoSize: true,
          });
  
          docDefinition = { content: htmlToPdf };
          create(docDefinition);
          setShow(true);
        }).catch((error) => {
          console.log(error);
        });
      }
    } */

  async function showModal(contractId, show) {
    if (show) {
      setShow(false);
      return;
    }

    try {
      const response = await axios.get(`api/contract/get-document-file/${contractId}`);

      if (response.data.isSigned) {
        setUrl(response.data.preSignedUrl);
        setShow(true);
        return;
      }

      const content = response.data.htmlContent;

      // Sadece PDF Blob'ı al
      const pdfBlob = await convertHtmlToPdf(content);

      // Blob'dan URL oluştur - bu kısım görüntüleme mantığına ait
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setUrl(pdfUrl);
      setShow(true);

    } catch (error) {
      console.error('Error in contract modal:', error);
    }
  }

  async function handleCellClick(e) {
    setSelectedRow(e.row);
  }

  async function signE() {
    setAnchorEl(null);
    const signedDocument = {
      documentId: signedRow.contractId,
      userId: userId,
      content: ""
    };

    await axios.get(`api/contract/get-contract/${signedRow.contractId}`).then(async (res) => {
      let htmlToPdf = res.data["contractHTMLContent"];
      const pdfBlob = await convertHtmlToPdf(htmlToPdf);
      signedDocument.content = await blobToBase64(pdfBlob);

      axios
        .post("api/sign/sign-e", signedDocument)
        .then((response) => {
          if (response.data.code === "100") toast.success("Doküman İmzaya GönderildiDosya imzalama aracına yönlendirildi.Şifrenizi girerek imzalayınız");
          else if (response.data.code === "101") {
            toast.info(
              <div>
                Digisoz İmzzacı uygulaması çalışmıyor veya giriş yapılmamış.
                <span>Eğer henüz indirmediyseniz aşağıda uygun olan uygulamayı indirin</span>
                <div style={{ marginTop: '10px' }}>
                  <button style={{ marginRight: '10px' }} onClick={() => downloadFile('W')}>Windows</button>
                  <button onClick={() => downloadFile('I')}>IOS</button>
                </div>
              </div>,
              {
                autoClose: false, // Otomatik kapanmasını istemiyoruz
                closeOnClick: true,
                draggable: true,
              }
            );
          } else {
            toast.error("Doküman imzalanırken hata oluştu.");
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          toast.error("Doküman imzalanırken hata oluştu.");
          console.log(error);
        });

    }).catch((error) => {
      console.log(error);
    });
  };

  /* async function signE() {
    setAnchorEl(null);
    const signedDocument = {
      documentId: signedRow.contractId,
      userId: userId
    };

    axios
      .post("api/sign/sign-e", signedDocument)
      .then((response) => {
        if (response.data.code === "100") toast.success("Doküman İmzaya GönderildiDosya imzalama aracına yönlendirildi.Şifrenizi girerek imzalayınız");
        else {
          toast.info(
            <div>
              Digisoz İmzzacı uygulaması çalışmıyor veya giriş yapılmamış.
              <span>Eğer henüz indirmediyseniz aşağıda uygun olan uygulamayı indirin</span>
              <div style={{ marginTop: '10px' }}>
                <button style={{ marginRight: '10px' }} onClick={() => downloadFile('W')}>Windows</button>
                <button onClick={() => downloadFile('I')}>IOS</button>
              </div>
            </div>,
            {
              autoClose: false, // Otomatik kapanmasını istemiyoruz
              closeOnClick: true,
              draggable: true,
            }
          );
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        toast.error("Doküman imzalanırken hata oluştu.");
        console.log(error);
      });
  } */

  async function downloadFile(system) {

    let url;
    await axios
      .get(`api/sign/get-signer-download-url`)
      .then((res) => {
        if (res.data.code === "100") {
          if (system === "W")
            url = res.data.windowsUrl;
          else
            url = res.data.iosUrl;
        } else {
          //console.log(res.data);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // İndirme işlemi başlatacak
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Modal show={show} onHide={() => setShow(false)}>
              <div onClick={() => setShow(false)}></div>
              <div style={{ position: "fixed" }}>
                {url && (
                  <object
                    style={{
                      width: "200%",
                      height: "75vh",
                    }}
                    data={url}
                    type="application/pdf"
                  >
                    <embed
                      frameborder="0"
                      width="300%"
                      height="500px"
                      src={url}
                      type="application/pdf"
                    />
                  </object>
                )}
              </div>
            </Modal>
            <Modal show={approveShow} onHide={() => setApproveShow(false)}>
              <Modal.Body>Teklif Onaya Gönderilecek emin misiniz?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setApproveShow(false)}
                >
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleSendContract()}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={archiveShow} onHide={() => setArchiveShow(false)}>
              <Modal.Body>Teklif Arşivlensin mi?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setArchiveShow(false)}>
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleArchive()}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
              <Modal.Body>Teklif Silinsin mi?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteShow(false)}
                >
                  Hayır
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleDelete()}
                >
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            {params.row.isSigned ? (
              <Tooltip title="İmzalı">
                <FileSignature color="#673AB7" size={48} />
              </Tooltip>
            ) : null}
            <Tooltip title="Görüntüle" onHide={() => setShow(false)}>
              <IconButton>
                <SlideshowIcon color="success" onClick={() =>
                  showModal(
                    params.row.contractId,
                    show
                  )
                }
                  onHide={() => setShow(false)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="İmzala">
              <IconButton onClick={() => setSignedRow(params.row)} >
                <BrushIcon
                  id="sign-positioned-button"
                  aria-controls={open ? "sign-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="sign-positioned-menu"
              aria-labelledby="sign-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => sign()}>Dijisöz İmza</MenuItem>
              <MenuItem onClick={signE}>e-İmza</MenuItem>
              <MenuItem onClick={handleClose}>Mobil İmza</MenuItem>
            </Menu>
            <Tooltip title="Onaya Gönder">
              <IconButton onClick={() => setApproveShow(true)}>
                <SendIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Arşivle">
              <IconButton onClick={() => setArchiveShow(true)}>
                <ArchiveIcon color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sil">
              <IconButton onClick={() => setDeleteShow(true)}>
                <DeleteIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined} />
      <PageHeader
        title="Taslak Teklifler"
        subTitle="Taslak Teklifleri görüntüleyebilirsiniz."
        icon={<ArchiveIcon fontSize="large" color="action" />}
      />
      <div className="home">
        <div className="homeContainer">
          <div className="listContainer">
            <div className="datatable">
              <div className="datatableTitle">
                Teklifler
              </div>
              <DataGrid
                className="datagrid"
                rows={data}
                getRowId={(row) => row.contractId}
                columns={userColumns.concat(actionColumn)}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                components={{ NoRowsOverlay }}
                pageSize={8}
                onCellClick={handleCellClick}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadedDocumentDraft;
