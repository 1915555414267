import axios from "axios";
import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ContractPreView = (props) => {
  const [contract, setContract] = useState("");
//  const { request } = useContract();
  const editor = useRef(null);
  const config = useMemo(() => ({
    readonly: true, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "",
    height: 600,
  }));

  useEffect(() => {
    createContractView();
  }, []);

  async function createContractView() {
    //console.log(request)
    await axios
      .post(`api/contract/create-contract-view`, props.contractInformations)
      .then((res) => {
        if (res.status === 200) {
          setContract(res.data.contractHTMLContent);
          toast.success("Dokümanınız hazırlandı", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.success("Dokümanınız hazırlanırken hata oluştu.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <div style={{ marginBottom: '15px' }}>
      <ToastContainer />
      <JoditEditor ref={editor} config={config} value={contract} />
    </div>
  );
};

export default ContractPreView;
