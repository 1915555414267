import { UploadedDocumentInfo } from "./UploadedDocumentInfo";

export interface SigneUploadedDocumentRequest {
    uploadedDocumentInfo: UploadedDocumentInfo;
    participantId: number | null;
}

export class SigneUploadedDocumentRequest implements SigneUploadedDocumentRequest {
    uploadedDocumentInfo: UploadedDocumentInfo;
    participantId: number | null;

    constructor(uploadedDocumentInfo: UploadedDocumentInfo, participantId: number | null) {
        this.uploadedDocumentInfo = uploadedDocumentInfo;
        this.participantId = participantId;
    }

    static create(): SigneUploadedDocumentRequest {
        return new SigneUploadedDocumentRequest(UploadedDocumentInfo.create(), null);
    }
}