import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { Endpoint } from '../../constant/Endpoint';
import { ResponseCode } from '../../constant/ResponseCode';
import { ApiResponse } from '../../type/ApiResponse';
import { StartFlowRequest } from '../../type/StartFlowRequest';
import { transformAndSetApiValidationError } from '../transformApiValidationError';

export const StartFlowService = async (request: StartFlowRequest, 
    setError: UseFormSetError<any>
): Promise<ApiResponse> => {
    const response = await axios.post<ApiResponse>(Endpoint.UploadedDocument.StartFlow, request);
    if(response.data.code === ResponseCode.VALIDATION_ERROR){
        transformAndSetApiValidationError(response.data.data, setError);
    }

    return response.data;
};