import axios from "axios";
import { UseFormSetError } from "react-hook-form";
import { Endpoint } from "../../constant/Endpoint";
import { ResponseCode } from "../../constant/ResponseCode";
import { ApiResponse } from "../../type/ApiResponse";
import { SigneUploadedDocumentRequest } from "../../type/SigneUploadedDocumentRequest";
import { transformAndSetApiValidationError } from "../transformApiValidationError";

export const SignEUploadedDocument = async(request: SigneUploadedDocumentRequest, setError: UseFormSetError<any>): Promise<ApiResponse> => {
    const response = await axios.post<ApiResponse>(Endpoint.Sign.SignEUploadedDocument, request);
    if(response.data.code === ResponseCode.VALIDATION_ERROR){
        transformAndSetApiValidationError(response.data.data, setError);
    }

    return response.data;
  };