import * as Validation from "yup";
import { ParticipantInfo, ParticipantInfoSchema } from "./ParticipantInfo";
import { UploadedDocumentInfo, UploadedDocumentInfoSchema } from "./UploadedDocumentInfo";

export interface StartFlowRequest {
    uploadedDocumentInfo: UploadedDocumentInfo;
    currentParticipant: ParticipantInfo;
    participantInfos: ParticipantInfo[];
    userId: number | null;
}

export class StartFlowRequest implements StartFlowRequest {
    uploadedDocumentInfo: UploadedDocumentInfo;
    participantInfos: ParticipantInfo[];
    userId: number | null;
    currentParticipant: ParticipantInfo;

    constructor(uploadedDocumentInfo: UploadedDocumentInfo, currentParticipant: ParticipantInfo, participantInfos: ParticipantInfo[], userId: number | null){
        this.uploadedDocumentInfo = uploadedDocumentInfo;
        this.currentParticipant = currentParticipant;
        this.participantInfos = participantInfos;
        this.userId = userId;
    }

    static create(): StartFlowRequest {
        return new StartFlowRequest (UploadedDocumentInfo.create(), ParticipantInfo.create(), ParticipantInfo.createList(), null);
    }
}

export const StartFlowRequestSchema = (t: Function) => 
  Validation.object().shape({
    uploadedDocumentInfo: UploadedDocumentInfoSchema(t),
    currentParticipant: ParticipantInfoSchema(t),        // CompanyInfo'nun doğrulama şeması
    participantInfos: Validation.array()
      .of(ParticipantInfoSchema(t))
      .min(1, t('validation:ParticipantsRequired'))
      .required(t('validation:ParticipantsRequired')),
    userId: Validation.number().required().nullable()
    .default(null)
    .transform((value) => value === undefined ? null : value),
});


