import * as Validation from "yup";
import { isValidIdentityNumber, isValidMobilePhoneNumber } from "../util/validation/Validator";

export interface ParticipantInfo {
    id: number | null;
    identityNumber: string;
    phoneNumber: string;
    emailAddress: string;
    documentId: number | null;
}

export class ParticipantInfo implements ParticipantInfo {
    id: number | null = null;
    identityNumber = '';
    phoneNumber = '';
    emailAddress = '';
    documentId: number | null = null;

    static create(): ParticipantInfo {
        return new ParticipantInfo();
    }

    static createList(): ParticipantInfo[] {
        const participantInfos: ParticipantInfo[] = [];
        /*participantInfos.push(ParticipantInfo.create());
        return participantInfos;  */
        return participantInfos;
    } 
}

export const ParticipantInfoSchema = (t: Function) =>
    Validation.object().shape({
        id: Validation.number()
            .nullable()
            .default(null)
            .transform((value) => value === undefined ? null : value),
        documentId: Validation.number()
            .nullable()
            .default(null)
            .transform((value) => value === undefined ? null : value),
        identityNumber: Validation.string().required(t('validation:NotBlank'))
            .test("ValidIdentityNumber", t('validation:ValidIdentityNumber'), isValidIdentityNumber),
        phoneNumber: Validation.string().required(t('validation:NotBlank'))
            .test("ValidMobilePhoneNumber", t('validation:ValidMobilePhoneNumber'), isValidMobilePhoneNumber),
        emailAddress: Validation.string().required(t('validation:NotBlank'))
            .email(t('validation:ValidEmailAddress')),
    });