
export const Endpoint = {
    Customer: {
        CompanySubscription: "/api/customer/company-subscription",
        GetCustomerByOwnerIdentityNumber: "/api/customer/get-all"
    },

    UploadedDocument: {
        StartFlow:  "/api/uploaded-document/start-flow",
        SignE: "/api/uploaded-document/signe"
    },

    Sign: {
        SignEUploadedDocument: "/api/sign/sign-e-uploaded-document"
    }
}