import { yupResolver } from '@hookform/resolvers/yup';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Send as SendIcon,
  FileUpload as UploadIcon,
  PersonAdd as UserPlusIcon
} from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { Pencil, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import FileViewer from "react-file-viewer";
import { useForm } from 'react-hook-form';
import { GetCustomerByOwnerIdentityNumber } from '../../api/customer/GetCustomerByOwnerIdentityNumber';
import { StartFlowService } from '../../api/document/StartFlowService';
import { SignEUploadedDocument } from '../../api/sign/SignEUploadedDocument';
import { ResponseCode } from '../../constant/ResponseCode';
import { Customer } from '../../type/Customer';
import { ParticipantInfo } from '../../type/ParticipantInfo';
import { SigneUploadedDocumentRequest } from '../../type/SigneUploadedDocumentRequest';
import { StartFlowRequest, StartFlowRequestSchema } from '../../type/StartFlowRequest';
import { convertToBase64 } from '../../util/FileOperation';

const StartFlow = () => {
  const { register, handleSubmit, setError,trigger , formState: { errors }, setValue, control, watch, getValues } = useForm<StartFlowRequest>({
    defaultValues: (() => {
      return StartFlowRequest.create();
    })(),
    resolver: yupResolver(StartFlowRequestSchema(t)),
  });


  type FileWithPreview = File & {
    preview?: string;
  };

  // State tanımlamaları
  const [document, setDocument] = useState<FileWithPreview | undefined>(undefined);
  const [documentError, setDocumentError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [otherDocumentType, setOtherDocumentType] = useState('');
  const [checkDocumentType, setCheckDocumentType] = useState(false);
  const [currentParticipantIndex, setCurrentParticipantIndex] = React.useState<number | null>(null);
  const [showValidation, setShowValidation] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSignMenu = Boolean(anchorEl);
  
  const renderParticipantErrors = (index: number) => {
    const participantErrors = errors.participantInfos?.[index];
    
    if (!participantErrors) return null;
  
    const getErrorMessage = (error: any): string | null => {
      if (typeof error === 'string') return error;
      if (error?.message && typeof error.message === 'string') return error.message;
      return null;
    };
  
    return (
      <Box sx={{ mt: 1, borderTop: '1px solid rgba(0, 0, 0, 0.12)', pt: 1 }}>
        {Object.entries(participantErrors).map(([field, error]) => {
          const errorMessage = getErrorMessage(error);
          
          if (!errorMessage) return null;
  
          return (
            <Typography 
              key={`${index}-${field}`} 
              color="error" 
              variant="caption" 
              display="block"
            >
              {errorMessage}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const signE = async () => {
    setShowValidation(true);
    const isValid = await trigger(['uploadedDocumentInfo']);
    console.log(errors);
    if (!isValid) {
      return;
    }

    const request = new SigneUploadedDocumentRequest(getValues("uploadedDocumentInfo"), null);
    const response = await SignEUploadedDocument(request, setError);
    console.log(response);
  }

  const handleSendClick = async () => {
    setShowValidation(true);
    const isValid = await trigger(['uploadedDocumentInfo']);
    console.log(errors);
    if (!isValid) {
      return;
    }

    const response = await StartFlowService(getValues(), setError);
          if (response.code === ResponseCode.SUCCESS) {
            console.log("işlem başarılı")
            //toast.success(t('companySubscription:ApplicationSent'));
            //history.push('/login');
          }else {
            console.log("errors ... : ")
            console.log(errors);
          }
  }

  const getCustomers = async () => {
    try {
      const customers: Customer[] = await GetCustomerByOwnerIdentityNumber("1");
      setCustomers(customers);
    }
    catch (error) {
      //toast.error(t("common.error"));
    }
  }
  useEffect(() => {
    getCustomers();
  }, []);

  const validateFile = (file: File): boolean => {
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png'
    ];

    if (!validTypes.includes(file.type)) {
      setDocumentError('Desteklenmeyen dosya formatı. Lütfen PDF, DOC, DOCX, JPG veya PNG yükleyin.');
      return false;
    }

    if (file.size > 10 * 1024 * 1024) {
      setDocumentError('Dosya boyutu 10MB\'dan küçük olmalıdır.');
      return false;
    }

    setDocumentError('');
    return true;
  };

  const createFilePreview = async (file: File) => {
    if (file.type.startsWith('image/')) {
      return URL.createObjectURL(file);
    }
    return undefined;
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        if (!validateFile(file)) {
          setIsLoading(false);
          return;
        }

        const base64Content = await convertToBase64(file);
        
        // Update form state with file name and content
        setValue("uploadedDocumentInfo.name", file.name);
        setValue("uploadedDocumentInfo.content", base64Content);

        const preview = await createFilePreview(file);
        const fileWithPreview = Object.assign(file, { preview });
        setDocument(fileWithPreview);
      }
    } catch (error) {
      setDocumentError('Dosya yüklenirken bir hata oluştu.');
    } finally {
      setIsLoading(false);
    }
  };

  /* const validateForm = () => {
    const errors = {
      identificationNumber: '',
      email: '',
      phone: ''
    };

    if (!/^\d{10,11}$/.test(formData.identificationNumber)) {
      errors.identificationNumber = 'Geçerli bir VKN/TCKN giriniz';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Geçerli bir e-posta adresi giriniz';
    }

    if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Geçerli bir telefon numarası giriniz';
    }

    setFormErrors(errors);
    return !Object.values(errors).some(error => error !== '');
  }; */

  const handleDeleteDocument = () => {
    if (document?.preview) {
      URL.revokeObjectURL(document.preview);
    }
    setDocument(undefined);
    setDocumentError('');
  };

  const participantList = watch("participantInfos");

  const addParticipant = () => {
    const currentParticipant = getValues("currentParticipant");
    const currentParticipants = getValues("participantInfos") || [];
    
    if (currentParticipantIndex !== null) {
      // Güncelleme işlemi
      const updatedParticipants = [...currentParticipants];
      updatedParticipants[currentParticipantIndex] = currentParticipant;
      setValue("participantInfos", updatedParticipants, { shouldValidate: true });
    } else {
      // Yeni ekleme işlemi
      setValue("participantInfos", [...currentParticipants, currentParticipant], { shouldValidate: true });
    }
    
    // Form'u sıfırla
    setValue("currentParticipant", ParticipantInfo.create());
    setCurrentParticipantIndex(null);
  };

  const deleteParticipant = (index: number) => {
    const currentParticipants = getValues("participantInfos");
    const updatedParticipants = currentParticipants.filter((_, idx) => idx !== index);
    setValue("participantInfos", updatedParticipants, { shouldValidate: true });
  };

  const signIconClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const signIconClose = () => {
    setAnchorEl(null);
  };

  const updateParticipant = (index: number) => {
    const participant = getValues("participantInfos")[index];
    setValue("currentParticipant", participant);
    setCurrentParticipantIndex(index);
  };

   useEffect(() => {
      // Eğer default city set edilmişse, districts de doldurulmalı
      if (watch('uploadedDocumentInfo.documentTypeId') !== 4) {
        setOtherDocumentType("");
      }
    }, [watch('uploadedDocumentInfo.documentTypeId')]);

    const renderPreview = () => {
      if (!document) {
        return (
          <Typography color="textSecondary" align="center">
            Doküman yüklenmedi
          </Typography>
        );
      }
    
      if (isLoading) {
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Yükleniyor...</Typography>
          </Box>
        );
      }
    
      if (document.type.startsWith('image/')) {
        return (
          <Box sx={{ height: '100%', overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <img
              src={document.preview || URL.createObjectURL(document)}
              alt="Preview"
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Box>
        );
      }
    
      return (
        <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <FileViewer
            fileType={document.type.split('/')[1]}
            filePath={document.preview || URL.createObjectURL(document)}
            errorComponent={() => <Typography color="error">Önizleme yüklenemedi</Typography>}
          />
        </Box>
      );
    };
    

  return (
    <Box sx={{ display: 'flex', height: '100vh', p: 2, gap: 2 }}>
      <Box sx={{ 
        width: '50%', 
        height: 'calc(100vh - 32px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        {/* Document Type Card - Adjusted height and scroll */}
        <Card sx={{ flex: '0 0 auto', maxHeight: '35vh' }}>
          <CardContent sx={{ height: '100%', overflow: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!getValues("uploadedDocumentInfo.documentTypeId") && showValidation}
                  size="small" // Reduced size
                >
                  <InputLabel>Doküman Tipi</InputLabel>
                  <Select
                    {...register("uploadedDocumentInfo.documentTypeId")}
                    label="Doküman Tipi"
                  >
                    <MenuItem value={1}>Sözleşme</MenuItem>
                    <MenuItem value={2}>Teklif</MenuItem>
                    <MenuItem value={4}>Diğer</MenuItem>
                  </Select>
                  {!getValues("uploadedDocumentInfo.documentTypeId") && showValidation && (
                    <FormHelperText>Doküman tipi seçimi zorunludur</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {getValues("uploadedDocumentInfo.documentTypeId") === 4 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small" // Reduced size
                    label="Diğer Doküman Tipi"
                    variant="outlined"
                    value={otherDocumentType}
                    onChange={(e) => setOtherDocumentType(e.target.value)}
                    required
                    error={!otherDocumentType.trim() && showValidation}
                    helperText={
                      !otherDocumentType.trim() && showValidation
                        ? 'Diğer doküman tipi zorunludur'
                        : ''
                    }
                  />
                </Grid>
              )}
              
              <Grid item xs={12}>
                <TextField
                  id="document-date"
                  type="date"
                  label="Doküman Tarihi"
                  variant="outlined"
                  fullWidth
                  size="small" // Reduced size
                  {...register("uploadedDocumentInfo.documentDate")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: '9999-12-31',
                    min: '1900-01-01'
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadIcon />}
                  fullWidth
                  size="small" // Reduced size
                >
                  Doküman Yükle
                  <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  />
                </Button>
              </Grid>
            </Grid>

            {showValidation && errors.uploadedDocumentInfo && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Alert severity="error">
                  Lütfen doküman bilgilerini kontrol ediniz
                </Alert>
              </Grid>
            )}

            {document && (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  borderRadius: 1,
                  bgcolor: 'background.paper'
                }}
              >
                <Typography variant="body2">{document.name}</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  
                  <Tooltip title="İmzala">
              <IconButton>
                <EditIcon
                  id="sign-positioned-button"
                  aria-controls={openSignMenu ? "sign-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSignMenu ? "true" : undefined}
                  onClick={signIconClick}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="sign-positioned-menu"
              aria-labelledby="sign-positioned-button"
              anchorEl={anchorEl}
              open={openSignMenu}
              onClose={signIconClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => signIconClose()}>Dijisöz İmza</MenuItem>
              <MenuItem onClick={() => signE()}>e-İmza</MenuItem>
              <MenuItem onClick={signIconClose}>Mobil İmza</MenuItem>
            </Menu>
                  <IconButton size="small" onClick={handleSendClick}>
                    <SendIcon sx={{ color: '#33C1FF' }} />
                  </IconButton>
                  <IconButton size="small" onClick={handleDeleteDocument}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Approver Card - Adjusted for better vertical space */}
        <Card sx={{ 
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(65vh - 16px)' // Increased height, accounting for gap
        }}>
          <CardContent sx={{ 
            height: '100%',
            p: '16px !important',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden' // Prevent double scrollbars
          }}>
            {/* Header Section */}
            <Box sx={{ flex: '0 0 auto', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <UserPlusIcon sx={{ mr: 1 }} />
                <Typography variant="h6">Onaycı Ekle</Typography>
              </Box>

              {/* Search Section */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small" // Reduced size
                    options={customers}
                    getOptionLabel={(option) => `${option.title} (${option.identityNumber})`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Müşteri Ara"
                        variant="outlined"
                        fullWidth
                        size="small" // Reduced size
                      />
                    )}
                    onChange={(_, value) => {
                      if (value) {
                        setValue("currentParticipant", {
                          id: null,
                          identityNumber: value.identityNumber,
                          emailAddress: value.email,
                          phoneNumber: value.phoneNumber,
                          documentId: null
                        });
                      } else
                        setValue("currentParticipant", ParticipantInfo.create());
                    }}
                  />
                </Grid>
              </Grid>

              {/* Input Fields */}
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <TextField
                    size="small" // Reduced size
                    label="VKN/TCKN"
                    variant="outlined"
                    fullWidth
                    {...register("currentParticipant.identityNumber")}
                    error={!!errors.currentParticipant?.identityNumber}
                    helperText={errors.currentParticipant?.identityNumber?.message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small" // Reduced size
                    label="E-posta"
                    variant="outlined"
                    fullWidth
                    {...register("currentParticipant.emailAddress")}
                    error={!!errors.currentParticipant?.emailAddress}
                    helperText={errors.currentParticipant?.emailAddress?.message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small" // Reduced size
                    label="Telefon"
                    variant="outlined"
                    fullWidth
                    {...register("currentParticipant.phoneNumber")}
                    error={!!errors.currentParticipant?.phoneNumber}
                    helperText={errors.currentParticipant?.phoneNumber?.message}
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small" // Reduced size
                startIcon={<UserPlusIcon />}
                onClick={addParticipant}
              >
                {currentParticipantIndex == null ? "Ekle" : "Güncelle"}
              </Button>

              {showValidation && errors.participantInfos && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {errors.participantInfos.message}
                </Alert>
              )}
            </Box>

            {/* Participant List with Scroll */}
            <Box sx={{ 
        flex: '1 1 auto',
        overflow: 'auto',
        mt: 2,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
          '&:hover': {
            background: '#555'
          }
        }
      }}>
              {participantList && participantList.map((participant, index) => (
          <Card key={index} sx={{ mb: 1, boxShadow: 1 }}>
            <CardContent sx={{ p: '8px !important' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2">{participant.identityNumber}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">{participant.emailAddress}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">{participant.phoneNumber}</Typography>
                  </Grid>
                </Grid>
                <Box>
                  <IconButton
                    onClick={() => updateParticipant(index)}
                    color="primary"
                    size="small"
                  >
                    <Pencil size={16} />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteParticipant(index)}
                    color="error"
                    size="small"
                  >
                    <Trash2 size={16} />
                  </IconButton>
                </Box>
              </Box>
              {renderParticipantErrors(index)}
            </CardContent>
          </Card>
        ))}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Preview Section - Right side */}
      <Box sx={{ width: '50%', height: 'calc(100vh - 32px)' }}>
        <Card sx={{ height: '100%' }}>
          <CardContent
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px dashed',
              borderColor: 'grey.300',
              p: 2,
              overflow: 'hidden'
            }}
          >
            {renderPreview()}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default StartFlow;